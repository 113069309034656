<template>
    <div class="menu-body" v-if="$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) ||
        $oidc.userProfile.roles.includes(roles.PLANTSUBPILLARLEADER) ||
        $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)
        ">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committed_year"
                            v-model="committedYr" disabled @change="filterHandler()">
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" disabled id="plantId"
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                {{ `${plant.plantName}, ${plant.regionCode}` }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                            :value="prevSelectedWorkCenter" @change="workcenterChanged($event)">
                            <option v-for="wc in workCenterList" v-bind:key="wc.workcenterId" :value="wc.workcenterId">
                                {{ wc.workcenterName }}
                            </option>
                        </select>
                    </div>
                    <div v-click-outside="onConfirmClick" class="plant-dd" style="margin-left: 10px">
                        <label for="Machine" class="form-label select-lable lable-text"> Machine </label>
                        <div @click="showDropdown">
                            <b-form-input class="form-control select-text select-text-pd cust-st-icon" :value="selectedText"
                                v-model="selectedText"> </b-form-input>
                        </div>
                        <div id="multi-select-options" class="multi-select" v-if="showOption">
                            <ul>
                                <li v-for="(option, index) in machineList" :key="index">
                                    <b-form-checkbox type="checkbox" class="radio" :value="option" name="chkMulti"
                                        @change="onOptionCheck($event, option, index)" v-model="selected"
                                        :disabled="option.isChecked">
                                        <label class="chklabel">{{ option.machineName }}</label>
                                    </b-form-checkbox>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 0 2rem" v-if="machineStatus">
                <div style="display: flex">
                    <div class="even-space-vertical-align">
                        <div class="ma-status">Machine Status :</div>
                        <div class="">
                            <span class="ma-status" style="vertical-align: middle; font-family: 'BarlowM', sans-serif">
                                {{ machineStatus }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="edit-button-div">
                    <b-button class="btn cancel-save-btn ed-st-btn" pill variant="outline-primary" @click="$router.go(-1)">
                        Cancel </b-button>

                    <b-button class="btn save-submit-btn ed-st-btn" pill block @click="updateHandle"
                        :disabled="isTargetApproved"> Update </b-button>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap">
                <div class="machine-target-setting">
                    <b-table-simple responsive outlined id="machine-target-setting" sticky-header
                        class="machine-target-table scroll gn-table-b">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th v-for="header in machineDetailHeader" :key="header.key">{{ header.label }}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(rowData, index) in machineDetail" :key="index">
                                <b-td class="machine-action-table-machine">
                                    {{ rowData.pillarName }}
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.jan.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.jan.value" min="0"
                                            :disabled="!rowData.jan.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'jan')" pattern="^[0-7]"
                                            @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.jan }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.feb.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.feb.value" min="0"
                                            :disabled="!rowData.feb.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'feb')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.feb }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.mar.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.mar.value" min="0"
                                            :disabled="!rowData.mar.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'mar')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.mar }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.apr.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.apr.value" min="0"
                                            :disabled="!rowData.apr.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'apr')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.apr }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.may.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.may.value" min="0"
                                            :disabled="!rowData.may.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'may')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.may }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.jun.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.jun.value" min="0"
                                            :disabled="!rowData.jun.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'jun')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.jun }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.jul.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.jul.value" min="0"
                                            :disabled="!rowData.jul.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'jul')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.jul }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.aug.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.aug.value" min="0"
                                            :disabled="!rowData.aug.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'aug')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.aug }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.sep.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.sep.value" min="0"
                                            :disabled="!rowData.sep.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'sep')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.sep }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.oct.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.oct.value" min="0"
                                            :disabled="!rowData.oct.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'oct')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.oct }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.nov.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.nov.value" min="0"
                                            :disabled="!rowData.nov.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'nov')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.nov }}</div>
                                </b-td>
                                <b-td>
                                    <div class="input-div" v-if="rowData.dec.hasOwnProperty('needInput')">
                                        <input class="input-f" v-model="rowData.dec.value" min="0"
                                            :disabled="!rowData.dec.needInput || rowData.isNonRegularPillar"
                                            @input="changingF(rowData.pillarName, $event, 'dec')" pattern="^[0-9]"
                                            maxlength="3" @keydown="isNumber" />
                                    </div>
                                    <div v-else>{{ rowData.dec }}</div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot> </b-tfoot>
                    </b-table-simple>
                </div>
            </div>

            <b-modal id="alert-msg" size="lg" ref="alertMsg" hide-footer :title="headTextForMulti"
                :visible="popUpForMultiValues">
                <div class="row col-12 page-content-regular">
                    <p>
                        <span class="page-heading-tab">
                            {{ messageForMulti }}
                        </span>
                    </p>
                </div>
                <div class="button-div" style="padding-left: 18rem">
                    <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                        class="btn cancel-save-btn button-sm" @click="OkAlertPopup()"> OK </b-button>
                    <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                        class="btn cancel-save-btn button-sm" @click="CancelAlertPopup()">
                        Cancel
                    </b-button>
                </div>
            </b-modal>

            <b-modal id="pillarAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true" size="sm"
                ref="pillarMsg" hide-footer hide-header-close :title="headerforPillar" :visible="popupPillar">
                <div class="row col-12 page-content-regular">
                    <p>
                        <span class="page-heading-tab">
                            {{ msgForPillar }}
                        </span>
                    </p>
                </div>
                <div class="button-div" style="padding-left: 18rem">
                    <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                        class="btn cancel-save-btn button-sm" @click="OkForPillar()"> OK </b-button>
                </div>
            </b-modal>
        </div>
        <MessagePopup :routToBack="true" class="alert" :message="errorMsg" :show="showPopup" :headText="headText" />
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import apiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import MessagePopup from "@/components/common/MessagePopup.vue";
import roles from "@/common/constants/roles";
export default {
    name: "MachineTargetSetting",
    data() {
        return {
            roles: roles,
            popUpForMultiValues: false,
            popupPillar: false,
            headerforPillar: "",
            msgForPillar: "",
            showLoader: true,
            plantList: [],
            workCenterList: [],
            committedYear: [],
            pillarList: [],
            workcenterId: 1,
            plantId: 1,
            machineId: 0,
            showOption: false,
            isNonRegularPillar: "",
            selectedText: "",
            messageForMulti: "",
            headTextForMulti: "",
            selected: [],
            chkChecked: true,
            committedYr: new Date().getFullYear(),
            machineDetailHeader: [
                {
                    key: "pillarName",
                    label: "Pillar",
                    sortable: false
                },
                {
                    key: "jan",
                    label: "Jan",
                    sortable: false
                },
                {
                    key: "feb",
                    label: "Feb",
                    sortable: false
                },
                {
                    key: "mar",
                    label: "Mar",
                    sortable: false
                },
                {
                    key: "apr",
                    label: "Apr",
                    sortable: false
                },
                {
                    key: "may",
                    label: "May",
                    sortable: false
                },
                {
                    key: "jun",
                    label: "Jun",
                    sortable: false
                },
                {
                    key: "jul",
                    label: "Jul",
                    sortable: false
                },
                {
                    key: "aug",
                    label: "Aug",
                    sortable: false
                },
                {
                    key: "sep",
                    label: "Sep",
                    sortable: false
                },
                {
                    key: "oct",
                    label: "Oct",
                    sortable: false
                },
                {
                    key: "nov",
                    label: "Nov",
                    sortable: false
                },
                {
                    key: "dec",
                    label: "Dec",
                    sortable: false
                }
            ],
            machineDetail: [],
            exceutiveApprove: false,
            exceutiveReview: false,
            plantManagerApprove: false,
            plantManagerReview: false,
            machineList: [],
            errorMsg: "",
            headText: "",
            showPopup: false,
            showMultiCheckPopup: false,
            isMultiCheckFunction: false,
            isTargetApproved: false,
            show: false,
            machineStatus: ""
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopup
    },
    directives: {
        clickOutside: {
            bind: function (el, binding, vnode) {
                console.log("clickOutside bind");
                el.event = function (event) {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.event);
            },
            unbind: function (el) {
                console.log("clickOutside unbind");
                document.body.removeEventListener("click", el.event);
            },
            beforeMount: (el, binding) => {
                console.log("clickOutside beforeMount");
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        binding.value();
                    }
                };
                document.addEventListener("click", el.clickOutsideEvent);
            },
            unmounted: (el) => {
                console.log("clickOutside unmounted");
                document.removeEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 3
        });
        this.$store.dispatch("breadcrumb", {
            title: "Plant Optimization Step Audit - Target Setting",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication Step Audit", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getCommittedYear();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData", "prevSelectedWorkCenter"])
    },
    methods: {
        showDropdown() {
            this.showOption = !this.showOption;
        },
        onConfirmClick() {
            if (this.showOption) {
                this.machineId = this.selected.length === 1 ? this.selected[0].machineId : 0;
                this.showLoader = true;
                this.filterHandler();
            }
            this.showOption = false;
        },
        getCommittedYear() {
            apiCalls.httpCallDetails(Api.MRCOMMITTEDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                if (!(this.committedYear && this.committedYear.length)) this.committedYear = [new Date().getFullYear];
                this.committedYr = new Date().getFullYear();
                this.getPlantDetails();
            });
        },
        getPlantDetails() {
            console.log("getPlantDetails");
            apiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId === this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId === this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName === this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId === event.target.value);
            this.getWorkCenterDetails();
        },
        getWorkCenterDetails() {
            apiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;
                let index = 0;
                if (this.prevSelectedWorkCenter) {
                    index = this.workCenterList.findIndex((el) => el.workcenterId === Number(this.prevSelectedWorkCenter));
                }
                this.wcId = this.workCenterList[index].workcenterId;
                this.$store.dispatch("prevSelectedWorkCenter", this.wcId);
                this.getMachineFilter();
            });
        },
        workcenterChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("prevSelectedWorkCenter", event.target.value);
            const changedWcId = this.workCenterList.find((el) => Number(el.workcenterId) === Number(event.target.value));
            this.wcId = changedWcId.workcenterId;
            this.machineId = 0
            this.getMachineFilter();
        },
        getMachineFilter() {
            const machinePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.wcId,
                regionId: this.plantId.regionId
            };
            this.machineList = [];
            this.selectedText = "";
            this.selected = [];
            apiCalls.httpCallDetails(Api.MRMACHINEDROPDOWN, "post", machinePayload).then((data) => {
                if (data.data.length) {
                    this.machineList = data.data.map((m) => ({
                        machineId: m.machineId,
                        machineName: m.machineName
                    }));
                    this.machineList.unshift({
                        machineId: 0,
                        machineName: "All",
                        checkedBox: true,
                        active: "Y"
                    });
                    this.markAllOptionCheck();
                    this.filterHandler();
                } else {
                    this.machineList = [];
                    this.selectedText = "";
                    this.selected = [];
                }
            });
        },
        filterHandler() {
            const machinePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.wcId,
                machineId: this.machineId,
                committedYear: this.committedYr
            };
            this.showLoader = true;
            apiCalls.httpCallDetails(Api.MRACTIONSCOREPILLARWISE, "post", machinePayload).then((data) => {
                this.showLoader = false;
                let respData = [];
                respData = data;
                this.isTargetApproved = respData.data.machineActionScore[0].isApproved;
                const pillarOrdData = [];
                const pillarNonOrdData = [];
                this.machineStatus = respData.data.machineStatus;
                let orderdData = [];
                for (const element of respData.data.machineActionScore) {
                    const a = element.pillarCode;
                    switch (a) {
                        case "DEC":
                            pillarOrdData.splice(0, 0, element);
                            element["isNonRegularPillar"] = false;
                            break;
                        case "WPO":
                            pillarOrdData.splice(1, 0, element);
                            element["isNonRegularPillar"] = false;
                            break;
                        case "CSD":
                            pillarOrdData.splice(2, 0, element);
                            element["isNonRegularPillar"] = false;
                            break;
                        case "RE":
                            pillarOrdData.splice(3, 0, element);
                            element["isNonRegularPillar"] = false;
                            break;
                        default:
                            pillarNonOrdData.push(element);
                            element["isNonRegularPillar"] = true;
                            break;
                    }
                }
                orderdData = pillarOrdData.concat(pillarNonOrdData);

                let resData = orderdData;
                let temp = [];
                resData.forEach((machineData, index) => {
                    let tempCurrent = {};
                    for (const month in machineData.target) {
                        if (Object.hasOwnProperty.call(machineData.target, month)) {
                            const element = machineData.target[month] ? machineData.target[month] : 0;
                            tempCurrent[month] = { value: element, needInput: true };
                        }
                    }
                    temp.push({
                        pillarId: machineData.pillarId,
                        pillarName: machineData.pillarCode,
                        isNonRegularPillar: machineData.isApproved,
                        ...tempCurrent

                    });
                });
                console.log(temp);
                this.machineDetail = temp;
                console.log("isTargetApproved", this.isTargetApproved);
            });
        },
        isNumber(evt) {

            let charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        decValueCheck(decValue, month) {
            this.machineDetail.forEach((pillar) => {
                switch (pillar.pillarName.toUpperCase()) {
                    case "WPO":
                        if (decValue >= 0 && decValue <= 3) {
                            pillar[month].value = Number(decValue) + 2;
                        } else {
                            pillar[month].value = 5;
                        }
                        break;
                    case "CSD":
                        if (decValue >= 0 && decValue < 7) {
                            pillar[month].value = decValue;
                        } else {
                            pillar[month].value = 6;
                        }
                        break;
                    case "RE":
                        if (decValue >= 0 && decValue <= 2) {
                            pillar[month].value = decValue;
                        } else {
                            pillar[month].value = decValue - 1;
                        }
                        break;
                    default:
                        break;
                }
            });
        },
        changingF(rowDataPillarName, event, month) {
            const decValue = event.target.value;
            const wpoValue = event.target.value;
            const csdValue = event.target.value;
            const reValue = event.target.value;

            if (rowDataPillarName && rowDataPillarName.toUpperCase() === "DEC") {
                if (decValue >= 8) {
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "DEC Pillar Step value should be less or equal to 7.";
                    this.machineDetail.forEach((pillar) => {
                        pillar[month].value = 0;
                    });
                } else {
                    this.decValueCheck(event.target.value, month);
                }
            }
            if (rowDataPillarName.toUpperCase() === "WPO") {
                if (wpoValue >= 6) {
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "WPO Pillar Step value should be less or equal to 5.";
                    const wpoPos = this.machineDetail.findIndex((pillar) => pillar.pillarName.toUpperCase() === "WPO");
                    this.machineDetail[wpoPos][month].value = 0;
                }
            }
            if (rowDataPillarName.toUpperCase() === "CSD") {
                if (csdValue > 6) {
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "CSD Pillar Step value should be less or equal to 6.";
                    const csdPos = this.machineDetail.findIndex((pillar) => pillar.pillarName.toUpperCase() === "CSD");
                    this.machineDetail[csdPos][month].value = 0;
                }
            }
            if (rowDataPillarName.toUpperCase() === "RE") {
                if (reValue > 6) {
                    this.popupPillar = true;
                    this.headerforPillar = "Alert";
                    this.msgForPillar = "RE Pillar Step value should be less or equal to 6.";
                    const rePos = this.machineDetail.findIndex((pillar) => pillar.pillarName.toUpperCase() === "RE");
                    this.machineDetail[rePos][month].value = 0;
                }
            }
        },
        onOptionCheck(event, option, index) {
            if (option.machineName === "All") {
                option.checkedBox = !option.checkedBox;
            }
            if (option.machineName === "All" && option.checkedBox) {
                this.markAllOptionCheck();
                return;
            } else {
                if (option.machineName === "All" && !option.checkedBox) {
                    this.selectedText = "";
                    this.selected = [];
                    return;
                }
            }

            this.selectedText = "";
            this.selected.forEach((element) => {
                this.selectedText += element.machineName + ", ";
            });
            this.selectedText = this.selectedText.substring(0, this.selectedText.length - 2);
            if (option.machineName !== "All" && this.selectedText.lastIndexOf("All") !== -1) {
                this.selected.splice(
                    event.findIndex((i) => i.machineName === "All"),
                    1
                );
                this.selectedText = this.selectedText.substring(this.selectedText.lastIndexOf("All") + 5, this.selectedText.length - 1);
            }

            this.machineId = this.selected.length === 1 ? this.selected[0].machineId : 0;
            this.showLoader = true;
            this.filterHandler();
        },
        markAllOptionCheck() {
            this.selectedText = "";
            this.selected = JSON.parse(JSON.stringify(this.machineList));

            this.selectedText = "All";
        },
        formatPayload(pillarValue) {
            return pillarValue ? pillarValue : 0
        },
        updateHandle() {
            this.showOption = false;

            this.showLoader = true;
            this.showPopup = false;

            if (this.selected.length === 0) {
                this.selected.unshift({ machineId: 0, machineName: "All" });
            } else if (this.selected.length === 1) {
                this.machineId = this.selected[0].machineId;
            } else {
                if (this.selected.findIndex((i) => i.machineId === 0) !== -1) {
                    this.selected = [];
                    this.selected.unshift({ machineId: 0, machineName: "All" });
                }
            }
            // SAVECURRENTANDAUDIT
            const actionTrackerTarget = [];

            this.selected.forEach((mlt) => {
                this.machineDetail.forEach((pillar) => {
                    const obj = {
                        pillarId: pillar.pillarId,
                        machineId: mlt.machineId,
                        createdBy: this.$oidc.userProfile.email,
                        actionTarget: {
                            jan: this.formatPayload(pillar.jan.value),
                            feb: this.formatPayload(pillar.feb.value),
                            mar: this.formatPayload(pillar.mar.value),
                            apr: this.formatPayload(pillar.apr.value),
                            may: this.formatPayload(pillar.may.value),
                            jun: this.formatPayload(pillar.jun.value),
                            jul: this.formatPayload(pillar.jul.value),
                            aug: this.formatPayload(pillar.aug.value),
                            sep: this.formatPayload(pillar.sep.value),
                            oct: this.formatPayload(pillar.oct.value),
                            nov: this.formatPayload(pillar.nov.value),
                            dec: this.formatPayload(pillar.dec.value),
                            lastUpdatedBy: this.$oidc.userProfile.email,
                            lastUpdatedTime: new Date().toISOString()
                        }
                    };
                    actionTrackerTarget.push(obj);
                });
            });

            const actionHeader = {
                commitedYear: this.committedYr,
                plantId: this.plantId.plantId,
                wcId: this.wcId
            };
            const post = { actionTrackerTarget, actionHeader };

            apiCalls.httpCallDetails(Api.SAVEMRPOACTIONTARGET, "post", post).then((data) => {
                this.showLoader = false;
                if (data.status) {
                    this.errorMsg = "Target values are updated successfully, please initiate approval process";
                    // please modify the filters to update the targets for other workcenter 
                    //  "Target Setting is Successfully Completed"; 
                    // Do you want to set target value for other work center
                    this.headText = "";
                    this.showPopup = true;
                } else {
                    this.errorMsg = data.message.trim();
                    this.headText = "";
                    this.showPopup = true;
                }
            });
        },
        OkAlertPopup() {
            this.popUpForMultiValues = false;
        },
        OkForPillar() {
            this.popupPillar = false;
        },

        CancelAlertPopup() {
            this.popUpForMultiValues = false;
        },
        plantManagerCheckbox(event, id) {
            if (event === true && id === "plant-manager-review" && this.plantManagerReview === true) {
                this.plantManagerReview = false;
            }
            if (event === true && id === "plant-manager-approve" && this.plantManagerApprove === true) {
                this.plantManagerApprove = false;
            }
            this.approveBy = "pm";
        },
        executiveCheckbox(event, id) {
            if (event === true && id === "executive-review" && this.exceutiveReview === true) {
                this.exceutiveReview = false;
            }
            if (event === true && id === "executive-approve" && this.exceutiveApprove === true) {
                this.exceutiveApprove = false;
            }
            this.approveBy = "rm";
        }
    }
};
</script>

<style scoped>
.chklabel {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 10px;
}

.dropdown {
    position: relative;
    cursor: pointer;
}

.graph-div {
    width: 25%;
    padding: 0 1%;
}

.multi-select {
    position: relative;
}

.multi-select ul {
    border: 1px solid #ddd;
    box-shadow: 2px 1px 4px 0.1rem rgb(0 0 0 / 25%);
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    list-style: none;
    max-height: 150px;
    z-index: 3;
    background-color: #fff;
    overflow: auto;
}

div.multi-select>ul>li:nth-child(n) {
    padding: 0.3rem;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-needInput-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.machine-target-setting {
    margin-top: 2rem;
    width: 100%;
}

.edit-button-div {
    display: flex;
}

.input-div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* width: 50px; */
}

.input-f {
    width: 30px;
    text-align: center;
    font-size: 1.33rem;
    border-radius: 4px;
    border: #313131 1px solid;
}

#machine-target-setting th.table-success {
    background-color: #f4f6fd;
}

#machine-target-setting th:first-child {
    background-color: white !important;
    font-weight: medium;
}

#machine-target-setting th:first-child,
#machine-target-setting td:first-child {
    text-align: left !important;
    padding-left: 2rem !important;
}

#machine-target-setting tr td {
    border: 0px !important;
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-target-setting-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-target-table {
    max-height: 37rem !important;
}

.machine-target-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-target-table thead th {
    text-align: center !important;
    background-color: #f7f9ff !important;
}

/* .machine-target-table thead th, */
.machine-target-table td {
    text-align: center !important;
    vertical-align: middle !important;
    min-width: 40px;
}

.save-submit-btn {
    background-color: #274ba7;
    border-color: #274ba7 !important;
    margin-top: 0.4rem;
}

.save-submit-btn:hover {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:active {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:focus {
    box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

#machine-target-setting>.machine-target-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-target-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-target-table th:first-child {
    width: 15%;
}

.machine-target-table td,
.machine-target-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-target-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-target-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-target-table .table-light,
.machine-target-table .table-light>td,
.machine-target-table .table-light>th {
    background-color: #f0f0f0 !important;
}



.machine-target-table th.table-success {
    background-color: #f4f6fd;
}

.machine-target-table th:first-child,
.machine-target-table td:first-child {
    background-color: transparent !important;
}

.machine-target-table td.table-success {
    background-color: transparent;
}

.machine-target-table tr {
    height: 5rem;
}

.machine-target-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.no-padding {
    padding: 0;
}

.btn {
    margin: 0 0 0 1rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

.full-length-width {
    width: 100%;
    text-align: center;
}

.approval-border-top {
    border-top: 1px solid #f0f0f0;
}

.approval-border-right {
    border-right: 1px solid #f0f0f0;
}

.review-background-active {
    background-color: #f0f7ff;
    border-radius: 6px;
    padding-top: 2em;
    padding-bottom: 2rem;
    max-width: 45%;
    margin-right: 5%;
}

.persona-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.approval-section-heading-disabled {
    color: #313131;
    opacity: 0.2;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}

.approval-section-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}

.approval-heading {
    color: #234372;
    font-size: 1.3333333333333333rem;
    font-family: "BarlowSb", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.approvals-updated-by {
    color: #5a5a5a;
    font-size: 0.9166666666666666rem;
    font-family: "BarlowR", sans-serif;
    text-align: center;
    padding: 1rem;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
